import React, { useState } from 'react';
import './createAccount.css';
//Components
import Navbar from '../../components/navbar/navbar';
//Assets
import isotipo from '../../assets/images/isotipos/RADARIA-ISOTIPO04-PNG.webp';
import FormSigIn from './FormSigIn/ForrmSigIn';
import FormSignUp from './FormSignUp/FormSignUp';


const CreateAccount = () => {
  const [isSignUp, setIsSignUp] = useState(false);

  return (
    <div className='container_login'>
      <Navbar />
      <div className='container d-flex'>
        <div className='container_form  p-3'>
          <div className="header">
            <div className='container_isotipo'>
              <img className='isotipo' src={isotipo} alt="" />
            </div>
            <div>
              <span
                className={`tab ${!isSignUp ? 'active' : ''}`}
                onClick={() => { setIsSignUp(false) }}
              >
                LOGIN
              </span>
              <span
                className={`tab ${isSignUp ? 'active' : ''}`}
                onClick={() => setIsSignUp(true)}
              >
                SIGN UP
              </span>
            </div>
          </div>
          <div className={`d-flex form-container ${isSignUp ? 'right-panel-active' : 'right-panel-desactive'} `}>
            <div className="form sign-in-form">
              <FormSigIn />
            </div>
            <div className="form sign-up-form">
              <FormSignUp />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default CreateAccount;