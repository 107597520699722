import React from 'react'
import './footer.css'

//Assets
import iconBlack from "../../assets/images/logotipos/RADARIA-LOGOTIPO01.png"
import { Link } from 'react-router-dom'
import btnTop from "../../assets/images/ButtonTop.webp"

const Footer = (idViewTop) => {
    return (
        <>
            <div className="container-fluid container-footer d-flex flex-column">
                <div className='d-flex row justify-content-center' >
                    <div className='col-md-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className="m-2"><img src={iconBlack} alt="Logo Radaria" width={"250px"} /></div>
                        <h2 className='text-info text-center m-2 fs-3'>Understand Your Progress</h2>
                        <div className='d-flex pe-2 ps-2 m-2'>
                            <input type="text" className='form-control border border-info rounded-5 me-3' placeholder='email@example.com' />
                            <button className='btn btn-primary '>Send</button>
                        </div>
                    </div>
                    <div className='col-md-3 d-flex flex-column justify-content-center align-items-center'>
                        <Link className=' text-primary m-2' to="#" >+525645645645</Link>
                        <Link className='text-primary m-2' to='#'>contacto@radaria.mx</Link>
                        <Link className=' text-primary m-2' to='#'>Polanco, CDMX,5260</Link>
                        <Link to="#Home">Go to top<img src={btnTop} alt="topBtn" className='ms-2' /></Link>
                    </div>
                </div>
                <div className='d-flex row text-info justify-content-center m-4'>
                    <h6 className='col-8 col-md-2 fw-bold text-center'>ABOUT US</h6>
                    <h6 className='col-8 col-md-2 fw-bold text-center'>MEMBERSHIP</h6>
                    <h6 className='col-8 col-md-2 fw-bold text-center'>COACHES</h6>
                    <h6 className='col-8 col-md-2 fw-bold text-center'>SERVICES</h6>
                    <h6 className='col-8 col-md-2 fw-bold text-center'>COMMUNITY</h6>
                    <h6 className='col-8 col-md-2 fw-bold text-center'>RUNNING CLUB</h6>
                </div>
                <div className='line-footer'><span className='text-dark m-2'>&copy;2024 Radaria. All rights reserverd</span></div>
            </div>
        </>
    )
}

export default Footer;