import React from "react";
import "./loading.css";
//Assets
import Isotipo from "../../assets/images/isotipos/RADARIA-ISOTIPO03.webp"

const Loading = ({ visible }) => {
    return (
        <div className={`container-loading ${visible ? "visible" : "hidden"}`}>

            <div className="spinner-grow" style={{ "width": "5rem", "height": "5rem" }} role="status">
                <span className=""><img src={Isotipo} alt="" style={{ "width": "5rem", "borderRadius": "50px" }} /></span>
            </div>

        </div>
    );
};

export default Loading;