// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/backgrounds/bg-services.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets//images/backgrounds/bg-small-services-description.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/images/backgrounds/bg-services-body.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-services {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
}

.container-info {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
    max-width: 700px;
}

.container-icon>img {
    vertical-align: middle;
}

.container-body-services {
    width: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
}

@media (min-width:1200px) {
    .container-info {
        position: relative;
        left: 20%;

    }

    .container-body-services {
        background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    }
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/services.css"],"names":[],"mappings":"AAAA;IACI,yDAAyE;IACzE,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,WAAW;IACX,iBAAiB;IACjB,uBAAuB;IACvB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,yDAA4F;IAC5F,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,WAAW;IACX,iBAAiB;IACjB,uBAAuB;IACvB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI;QACI,kBAAkB;QAClB,SAAS;;IAEb;;IAEA;QACI,yDAA8E;IAClF;AACJ","sourcesContent":[".container-services {\n    background-image: url(\"../../assets/images/backgrounds/bg-services.webp\");\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    width: 100%;\n    min-height: 100vh;\n    background-color: black;\n    display: flex;\n    flex-direction: column;\n}\n\n.container-info {\n    display: flex;\n    flex-direction: column;\n    margin: auto;\n    justify-content: center;\n    align-items: center;\n    max-width: 700px;\n}\n\n.container-icon>img {\n    vertical-align: middle;\n}\n\n.container-body-services {\n    width: 100%;\n    background-image: url(\"../../assets//images/backgrounds/bg-small-services-description.webp\");\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    width: 100%;\n    min-height: 100vh;\n    background-color: black;\n    display: flex;\n    flex-direction: column;\n}\n\n@media (min-width:1200px) {\n    .container-info {\n        position: relative;\n        left: 20%;\n\n    }\n\n    .container-body-services {\n        background-image: url(\"../../assets/images/backgrounds/bg-services-body.webp\");\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
