// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-description {
    width: 100%;
    min-height: 100vh;
}

.side-shape-left {
    position: absolute;
    left: 0px;
    background-color: var(--bs-primary);
    width: 50%;
    height: 15px;
    border-radius: 0px 10px 10px 0px;
}

.side-shape-right {
    background-color: var(--bs-primary);
    width: 50%;
    height: 15px;
    border-radius: 10px 0px 0px 10px;
    position: absolute;
    right: 0px;
}

.container-body-description {
    margin: auto;
    height: 90vh;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.united {
    margin-bottom: 0px !important;
}


@media (min-width:1200px) {
    .container-body-description {
        width: 30%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Views/Services/SectionDescription/sectionDescription.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,mCAAmC;IACnC,UAAU;IACV,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,mCAAmC;IACnC,UAAU;IACV,YAAY;IACZ,gCAAgC;IAChC,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;AACjC;;;AAGA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".container-description {\n    width: 100%;\n    min-height: 100vh;\n}\n\n.side-shape-left {\n    position: absolute;\n    left: 0px;\n    background-color: var(--bs-primary);\n    width: 50%;\n    height: 15px;\n    border-radius: 0px 10px 10px 0px;\n}\n\n.side-shape-right {\n    background-color: var(--bs-primary);\n    width: 50%;\n    height: 15px;\n    border-radius: 10px 0px 0px 10px;\n    position: absolute;\n    right: 0px;\n}\n\n.container-body-description {\n    margin: auto;\n    height: 90vh;\n    width: 70%;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.united {\n    margin-bottom: 0px !important;\n}\n\n\n@media (min-width:1200px) {\n    .container-body-description {\n        width: 30%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
