import React, { useState } from "react";
import './formvalidate.css'
import { useNavigate } from "react-router-dom";
import validate from "./validate";
//Assets
import profileUser from '../../../assets/images/profile1.webp'
//Service
import { completeRegister } from "../../../services/users/userService";
//Components
import Loading from "../../../components/Loading/Loading";
import toast, { Toaster } from "react-hot-toast";
const FormValidateInfo = ({ token }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [selectImage, setSelectImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const navigate = useNavigate();
    const initialValues = {
        firstName: "",
        lastName: "",
        dateBirth: "",
        street: "",
        city: "",
        state: "",
        postalCode: "",
        gender: "",
        nickname: "",
        phoneNumber: "",
    }
    const [formValues, setFormValues] = useState(initialValues);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });

    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectImage(imageUrl);
            setImageFile(file);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate(formValues);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setLoading(!loading);
            const userData = { ...formValues };
            userData.phoneNumber = '+52' + formValues.phoneNumber;
            // Send data or API
            const formData = new FormData();
            if (imageFile) {
                formData.append("img", imageFile);
            }
            formData.append("firstName", userData.firstName);
            formData.append("lastName", userData.lastName);
            formData.append("dateBirth", userData.dateBirth);
            formData.append("street", userData.street);
            formData.append("city", userData.city);
            formData.append("state", userData.state);
            formData.append("postalCode", userData.postalCode);
            formData.append("gender", userData.gender);
            formData.append("nickname", userData.nickname);
            formData.append("phoneNumber", userData.phoneNumber);

            const responseApi = await completeRegister(formData, token)

            if (!responseApi.status) {
                setLoading(false);
                toast.error(`${responseApi.message}`, { duration: 10000 })
            } else {
                setLoading(false);
                toast.success(`${responseApi.data.message}`, { duration: 10000 })
                setFormValues(initialValues);
                setSelectImage(null)
                setErrors({});
                setTimeout(() => {
                    navigate(`/verifyCode/${token}`, { replace: true });
                }, 5000)
            }
        }

    };


    return (
        <>
            <Loading visible={loading} />
            <Toaster
                position="top-right"
                reverseOrder={false}
                containerStyle={{
                    top: 100,
                    left: 20,
                    bottom: 20,
                    right: 50,
                }}
                toastOptions={{
                    success: {
                        style: {
                            background: 'rgba(0, 0, 0, 50%)',
                            color: 'white'

                        },
                    },
                    error: {
                        style: {
                            background: 'rgba(0, 0, 0, 50%)',
                            color: 'white'
                        },
                    },
                }}

            />
            <div className="d-flex flex-column align-items-center mt-3">
                <form onSubmit={handleSubmit} className="d-flex flex-column needs-validation">
                    <div className="container-head-form" >
                        <div className="container-img">
                            <label htmlFor="fileInput">
                                {<img src={selectImage || profileUser} alt="Perfil" style={{ width: '150px', height: '150px', borderRadius: '50%' }} />}
                            </label>
                            <input type="file" accept="image/*" id="fileInput" onChange={handleImageChange} style={{ display: 'none' }} />
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                name="nickname"
                                value={formValues.nickname}
                                onChange={handleChange}
                                className={`form-control ${errors.nickname && "is-invalid input-error"} text-light`}
                                id="nickName"
                                placeholder="Your nickname"
                            />
                            {errors.nickname &&
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {errors.nickname}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="form-validate-info mt1">
                        <div className="mb-3">
                            <label className="form-label">FIRST NAME</label>
                            <input
                                type="text"
                                className={`form-control ${errors.firstName && "is-invalid input-error"} text-light`}
                                id="firstName"
                                name="firstName"
                                value={formValues.firstName}
                                onChange={handleChange}
                                placeholder="Your name"
                            />
                            {errors.firstName &&
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {errors.firstName}
                                </div>
                            }
                        </div>
                        <div className="">
                            <label className="form-label">LAST NAME</label>
                            <input
                                type="text"
                                name="lastName"
                                value={formValues.lastName}
                                onChange={handleChange}
                                className={`form-control ${errors.lastName && "is-invalid input-error"} text-light`}
                                id="lastName"
                                placeholder=" Your last name"
                            />
                            {errors.lastName &&
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {errors.lastName}
                                </div>
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label">BIRTHDATE</label>
                            <input
                                type="date"
                                name="dateBirth"
                                value={formValues.dateBirth}
                                onChange={handleChange}
                                className={`form-control ${errors.dateBirth && "is-invalid input-error"} text-light`}
                                id="birthdate"
                            />
                            {errors.dateBirth &&
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {errors.dateBirth}
                                </div>
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label">STREET</label>
                            <input
                                type="text"
                                name="street"
                                value={formValues.street}
                                onChange={handleChange}
                                className={`form-control ${errors.street && "is-invalid input-error"} text-light`}
                                id="street"
                            />
                            {errors.street &&
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {errors.street}
                                </div>
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label">CITY</label>
                            <input
                                type="text"
                                name="city"
                                value={formValues.city}
                                onChange={handleChange}
                                className={`form-control ${errors.city && "is-invalid input-error"} text-light`}
                                id="city"
                            />
                            {errors.city &&
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {errors.city}
                                </div>
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label">STATE</label>
                            <input
                                type="text"
                                name="state"
                                value={formValues.state}
                                onChange={handleChange}
                                className={`form-control ${errors.state && "is-invalid input-error"} text-light`}
                                id="state"
                            />
                            {errors.state &&
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {errors.state}
                                </div>
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label">POSTAL CODE</label>
                            <input
                                type="number"
                                name="postalCode"
                                value={formValues.postalCode}
                                onChange={handleChange}
                                className={`form-control ${errors.postalCode && "is-invalid input-error"} text-light`}
                                id="postalCode"
                            />
                            {errors.postalCode &&
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {errors.postalCode}
                                </div>
                            }
                        </div>

                        <div className="mb-3">
                            <label className="form-label">GENDER</label>
                            <select
                                id="gender"
                                name="gender"
                                value={formValues.gender}
                                onChange={handleChange}
                                className={`form-select ${errors.gender && "is-invalid input-error"} text-light`}>
                                <option value="slect">Seleccione una opción</option>
                                <option value="male">Hombre</option>
                                <option value="female">Mujer</option>
                                <option value="other">Otro</option>

                            </select>
                            {errors.gender &&
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {errors.gender}
                                </div>
                            }
                        </div>
                        <div className="mb-3">
                            <label className="form-label">PHONE NUMBER</label>
                            <input
                                type="tel"
                                name="phoneNumber"
                                value={formValues.phoneNumber}
                                onChange={handleChange}
                                className={`form-control ${errors.phoneNumber && "is-invalid input-error"} text-light`}
                                id="phoneNumber"
                                placeholder="your phone number"
                            />
                            {errors.phoneNumber &&
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {errors.phoneNumber}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="col-auto d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary mb-3">CREATE</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default FormValidateInfo;