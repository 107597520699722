const validate = (formValues) => {
    const errors = {};
    // Name validation
    if (!formValues.firstName.trim()) {
        errors.firstName = 'The name is required';
    } else if (formValues.firstName.length < 2) {
        errors.firstName = 'The name must be at least 2 characters';
    }
    //Last Name validation
    if (!formValues.lastName.trim()) {
        errors.lastName = 'The lastname is required';
    } else if (formValues.lastName.length < 2) {
        errors.lastName = 'The lastname must be at least 2 characters';
    }
    //Birthdate validate
    if (!formValues.dateBirth.trim()) {
        errors.dateBirth = 'The dateBirth is required'
    }

    //NickName validate
    if (!formValues.nickname.trim()) {
        errors.nickname = 'The nickname is required';
    } else if (formValues.nickname.length < 2) {
        errors.nickname = 'The nickname must be at least 2 characters';
    }

    //Phone validate
    if (!formValues.phoneNumber) {
        errors.phoneNumber = 'The phone is required';
    } else if (!/^[0-9]{10}$/.test(formValues.phoneNumber)) {
        errors.phoneNumber = 'Invalida phone number';
    }
    //Postal Code Validate
    if (!formValues.postalCode.trim()) {
        errors.nickname = 'The postal code is required';
    } else if (!/^[0-9]{5}$/.test(formValues.postalCode)) {
        errors.postalCode = 'Invalida postal code number';
    }
    //Gender validate
    if (formValues.gender === '') {
        errors.gender = 'The gender is required';
    } else if (formValues.gender !== 'male' && formValues.gender !== 'female' && formValues.gender !== 'other') {
        errors.gender = 'The gender is invalid';
    }

    return errors;

}
export default validate