import React from "react";
import "./sectionDescription.css"
//Assets
import ReadySetGo from "../../../components/ReadySetGo/ReadySetGo";

const SectionDescription = () => {

    return (
        <section className="container-description">
            <div className="side-shape-left" ></div>
            <article className="container-body-description">
                <div className="text-info fs-2">
                    <div className="d-flex">
                        <b className="text-secondary fs-1 me-2">Radaria</b>
                        <p className="d-flex united">a united </p>
                    </div>
                    <p className="d-flex justify-content-center">community</p>
                </div>
                <p className="text-info text-center">Every step matters, especially when it is alongside runners like you, where experiences come together into one, for a path full of movement.</p>
                <ReadySetGo />
            </article>
            <div className="side-shape-right"></div>

        </section>
    );
};
export default SectionDescription;