// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.memberships-container{
    text-align: center;
    color: white;
}

.header-image{
    position: relative;
    text-align: center;
}

.header-image .overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}
.cards-container{
    margin-top: -100px;
}
.card{
    background: white;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 20px;
}
.card-platinum{
    background-color: #f0f0f0;
  }
  
  .card-black{
    background-color: #000;
    color: white;
  }
  
  .card-gold{
    background-color: #f9e500;
  }
  
  .membership-info {
    padding: 20px;
    background-color: #111;
  }
`, "",{"version":3,"sources":["webpack://./src/Views/Memberships/memberships.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB","sourcesContent":[".memberships-container{\r\n    text-align: center;\r\n    color: white;\r\n}\r\n\r\n.header-image{\r\n    position: relative;\r\n    text-align: center;\r\n}\r\n\r\n.header-image .overlay-content {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    color: white;\r\n}\r\n.cards-container{\r\n    margin-top: -100px;\r\n}\r\n.card{\r\n    background: white;\r\n    margin-bottom: 20px;\r\n    padding: 20px;\r\n    border-radius: 20px;\r\n}\r\n.card-platinum{\r\n    background-color: #f0f0f0;\r\n  }\r\n  \r\n  .card-black{\r\n    background-color: #000;\r\n    color: white;\r\n  }\r\n  \r\n  .card-gold{\r\n    background-color: #f9e500;\r\n  }\r\n  \r\n  .membership-info {\r\n    padding: 20px;\r\n    background-color: #111;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
