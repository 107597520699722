import apiClient from '../apiClient';

export const registerUser = async (userData) => {
    try {
        const response = await apiClient.post("users/newUser", userData);
        return {
            status: true,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            // API responding errors
            return {
                status: false,
                message: error.response.data.message || 'Your request cannot be processed at this time, please try again later.',
                statusCode: error.response.status,
            };
        } else if (error.request) {
            // Errors related to the connection or request
            return {
                status: false,
                message: 'Could not connect to the server. Check your connection or the URL.',
                errorCode: error.code,
            };
        } else {
            // Other unknown errors (e.g. Axios configurations)
            return {
                status: false,
                message: 'An unexpected error occurred while making the request.',
                errorCode: error.code,
            };
        }
    }
};

export const completeRegister = async (formData, token) => {
    try {
        const response = await apiClient.put("users/completeInformation", formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        return {
            status: true,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            // API responding errors
            return {
                status: false,
                message: error.response.data.message || 'Your request cannot be processed at this time, please try again later.',
                statusCode: error.response.status,
            };
        } else if (error.request) {
            // Errors related to the connection or request
            return {
                status: false,
                message: 'Could not connect to the server. Check your connection or the URL.',
                errorCode: error.code,
            };
        } else {
            // Other unknown errors (e.g. Axios configurations)
            return {
                status: false,
                message: 'An unexpected error occurred while making the request.',
                errorCode: error.code,
            };
        }
    }
};

export const validateSMS = async (userData, token) => {
    try {
        const response = await apiClient.put("verify/verify-sms", userData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return {
            status: true,
            data: response.data,
        };
    } catch (error) {
        if (error.response) {
            // API responding errors
            return {
                status: false,
                message: error.response.data.message || 'Your request cannot be processed at this time, please try again later.',
                statusCode: error.response.status,
            };
        } else if (error.request) {
            // Errors related to the connection or request
            return {
                status: false,
                message: 'Could not connect to the server. Check your connection or the URL.',
                errorCode: error.code,
            };
        } else {
            // Other unknown errors (e.g. Axios configurations)
            return {
                status: false,
                message: 'An unexpected error occurred while making the request.',
                errorCode: error.code,
            };
        }
    }
};
