import React from "react";
import "./sectionSummary.css"
//Assets
import FormRadaria from "../../../assets/images/FormRadaria.webp"
import IconHalt from "../../../assets/icons/iacon-healt.svg"
import IconClubs from "../../../assets/icons/icon-clubs.svg"
import IconGirl from "../../../assets/icons/icon-girl.svg"
import IconPlans from "../../../assets/icons/icon-nutrition-plans.svg"
import IconRunningClubs from "../../../assets/icons/icon-running-clubs.svg"
import IconRunner from "../../../assets/icons/icon-running.svg"



const SectionSummary = () => {

    return (
        <>
            <section className="d-flex flex-column align-items-center">
                <div className="container-form">
                    <img src={FormRadaria} alt="Frorm Radaria" className="form-radaria " />
                </div>
                <article className="cards-container-services mt-5 ">
                    <div className="d-flex row justify-content-center container-cards-little mt-5">
                        <div className="card-container-services text-info col-9 col-lg-4 p-4 d-flex flex-column justify-content-center align-items-center " >
                            <img src={IconHalt} alt="Icon biceps" width={60} />
                            <h2 className="fs-4 fw-bold">Community</h2>
                            <p className="fs-6 text-center">Running is a method of terrestrial locommotion allowing humans and other animals to move rapidly on foot.</p>
                        </div>
                        <div className="card-container-services text-info col-9 col-lg-4 p-4 d-flex flex-column justify-content-center align-items-center borders-sides " >
                            <img src={IconClubs} alt="Icon" width={60} />
                            <h2 className="fs-4 fw-bold">Running Clubs</h2>
                            <p className="fs-6 text-center">Running is a method of terrestrial locommotion allowing humans and other animals to move rapidly on foot.</p>
                        </div>
                        <div className="card-container-services text-info col-9 col-lg-4 p-4 d-flex flex-column justify-content-center align-items-center" >
                            <img src={IconGirl} alt="Icon" width={60} />
                            <h2 className="fs-4 fw-bold">Fitness Running</h2>
                            <p className="fs-6 text-center">Running is a method of terrestrial locommotion allowing humans and other animals to move rapidly on foot.</p>
                        </div>
                    </div>
                    <div className="d-flex row justify-content-center container-cards-little container-border-top">
                        <div className="card-container-services text-info  col-9 col-lg-4 p-4 d-flex flex-column justify-content-center align-items-center " >
                            <img src={IconPlans} alt="Icon" width={60} />
                            <h2 className="fs-4 fw-bold">Meeting points</h2>
                            <p className="fs-6" text-center>Running is a method of terrestrial locommotion allowing humans and other animals to move rapidly on foot.</p>
                        </div>
                        <div className="card-container-services text-info col-9 col-lg-4 p-4 d-flex flex-column justify-content-center align-items-center borders-sides" >
                            <img src={IconRunningClubs} alt="Icon" width={60} />
                            <h2 className="fs-4 fw-bold">Training routes</h2>
                            <p className="fs-6 text-center">Running is a method of terrestrial locommotion allowing humans and other animals to move rapidly on foot.</p>

                        </div>
                        <div className="card-container-services text-info col-9 col-lg-4 p-4 d-flex flex-column justify-content-center align-items-center " >
                            <img src={IconRunner} alt="Icon" width={60} />
                            <h2 className="fs-4 fw-bold">Nutritional plans</h2>
                            <p className="fs-6 text-center">Running is a method of terrestrial locommotion allowing humans and other animals to move rapidly on foot.</p>
                        </div>
                    </div>
                </article>

            </section>
        </>
    );
};
export default SectionSummary;