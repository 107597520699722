import React from "react"
import './styles/welcome-home.css'
//Assets
import ImgRunner from "../../assets/images/pair-running-welcome.png";
import Isotipo from "../../assets/images/isotipos/RADARIA-ISOTIPO04.webp";
import ImgCard1 from "../../assets/images/card-img-1.png";
import ImgCard2 from "../../assets/images/card-img-2.png";
import ImgCard3 from "../../assets/images/card-img-3.png";
import gifRad from "../../assets/images/gif.gif"
//Components
import ProgressBar from "../../components/progressbar/ProgressBar"



const WelcomeHome = () => {
    return (
        <>
            <div className="container-fluid ">
                <div className="container-progress">
                    <ProgressBar />
                </div>
                <div className="container-fluid welcome-bg d-flex align-items-center">
                    <div className="container-text ms-lg-5 ps-lg-5 p-3">
                        <h1>Welcome to <span id="text-radaria" className="">Radaria</span></h1>
                        <div className="container-btn">
                            <div className="divider-linear"></div>
                            <button type="button" className="btn btn-primary ">Conoce más...</button>
                        </div>
                        <p>Encouraging an environment that inspires and motivates runners to achieve their
                            goals and exceed your limits</p>
                    </div>
                </div>
                <div className="container mt-5 mb-5">
                    <div className="row d-flex align-items-center">
                        <div className="mx-auto container-img d-flex justify-content-center  col-lg-5">
                            <img className="mx-auto d-block " src={ImgRunner} alt="Runner Radaria" />
                        </div>
                        <div className="col-lg-7 mt-5">
                            <h2 className="d-flex justify-content-center">Be part of<span className="ms-2 nameRad"><img src={gifRad} alt="img Gif" width={"100px"} />Radaria</span></h2>
                            <p>Every step matters and even more so if it is with runners like you, where experiences converge into one, for a path full of movement.</p>
                            <div>
                                <div className="line-asset" ><div className="line-secondary"></div><img src={Isotipo} alt="" /><div className="line-secondary"></div></div>
                                <div className="row d-flex  justify-content-around mt-3 mb-3">
                                    <div className="card col-lg-3 col-8 m-3" >
                                        <img className="card-img-top" src={ImgCard1} alt="img card Radaria" />
                                        <div className="card-body  p-2 d-flex flex-column justify-content-center">
                                            <h3 className="card-title fs-5">Conecta tus pasos</h3>
                                            <p className="fs-6">Paso a pasito, un mundo dodsdssnde cada  paso es una historia.</p>
                                        </div>
                                    </div>
                                    <div className="card col-lg-3  col-8 m-3">
                                        <img className="card-img-top" src={ImgCard2} alt="img card Radaria" />
                                        <div className="card-body  p-2  d-flex flex-column justify-content-center ">
                                            <h3 className="card-title fs-5">Un trayecto en conjunto</h3>
                                            <p>Paso a pasito, un mundo dodsdssnde cada  paso es una historia.</p>
                                        </div>
                                    </div>
                                    <div className="card col-lg-3 col-8 m-3">
                                        <img src={ImgCard3} alt="img card Radaria" className="card-img-top " />
                                        <div className="card-body  p-2 d-flex flex-column justify-content-center">
                                            <h3 className="card-title fs-5">Una comunidad unida</h3>
                                            <p>Paso a pasito, un mundo dodsdssnde cada  paso es una historia.</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="line-asset"><div className="line-secondary"></div><img src={Isotipo} alt="" /><div className="line-secondary"></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default WelcomeHome;