// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-footer {
    /* background-image: url('../../assets/images/pattern_bg_green.webp'); */
    background-color: black;
    justify-content: center;
    flex-direction: column;
    display: flex;
}

.line-footer {
    width: 100%;
    background-color: var(--bs-primary);
    display: flex;
    justify-content: center;
}

a {
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,wEAAwE;IACxE,uBAAuB;IACvB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,mCAAmC;IACnC,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".container-footer {\n    /* background-image: url('../../assets/images/pattern_bg_green.webp'); */\n    background-color: black;\n    justify-content: center;\n    flex-direction: column;\n    display: flex;\n}\n\n.line-footer {\n    width: 100%;\n    background-color: var(--bs-primary);\n    display: flex;\n    justify-content: center;\n}\n\na {\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
