import "./navbar.css";
//Assets
import uconUser from "../../assets/icons/user-circle.svg";
import iconRadaria from "../../assets/images/logotipos/RADARIA-VARIACIÓN04.png";
const Navbar = ({ page }) => {

    return (
        <>
            <div className="containe-fluid d-flex justify-content-between ps-4 pe-4 pt-4">
                <a className="navbar-brand " href="/"><img className="icon_radaria" src={iconRadaria} alt="icon the radaria" /></a>
                <nav className="navbar navbar-expand-lg ">
                    <div className="container-fluid container-nav">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item ms-3 me-3 d-flex align-items-center">
                                    <a className="nav-link d-flex align-items-center" aria-current="page" href="/about-us">ABOUT US</a>
                                </li>
                                <li className="nav-item ms-3 me-3 d-flex align-items-center ">
                                    <a className="nav-link d-flex align-items-center" href="/memberships">MEMBERSHIP</a>
                                </li>
                                <li className="nav-item ms-3 me-3 d-flex align-items-center ">
                                    <a className="nav-link d-flex align-items-center" href="/coaches">COACHES</a>
                                </li>
                                <li className="nav-item ms-3 me-3 d-flex align-items-center ">
                                    <a className={`nav-link ${page === "services" ? "active" : ""}  d-flex align-items-center`} href="/services">SERVICES</a>
                                </li>
                                <li className="nav-item ms-3 me-3 d-flex align-items-center ">
                                    <a className="nav-link  d-flex align-items-center" href="/services">COMMUNITY</a>
                                </li>
                                <li className="nav-item ms-3 me-3 d-flex align-items-center ">
                                    <a className="nav-link  d-flex align-items-center" href="/services">RUNNING CLUB</a>
                                </li>
                                <li className="btn_signIng nav-item ms-3 me-3 d-flex align-items-center">
                                    <a className="nav-link  d-flex align-items-center" href="/auth">
                                        <img src={uconUser} alt="Icon User" className="me-2" /> SIGN IN
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

            </div>

        </>
    )
}

export default Navbar