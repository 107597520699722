import React from "react";
import "./readySetGo.css";
//Assets
import LogoRadaria from "../../assets/images/isotipos/RADARIA-ISOTIPO02.webp"


const ReadySetGo = () => {
    return (
        <div className="element-go text-info mt-3">
            <img src={LogoRadaria} alt="Logo radaria" width={50} />
            Ready, set... Go!
        </div>
    );
};
export default ReadySetGo;