import React from "react";
import "./servicesOffered.css"
//Assets
import FormMotionTribe from "../../../assets/images/form-MotionTribe.webp";
import ImgBorder1 from "../../../assets/images/img-border1.webp";
import ImgBorder2 from "../../../assets/images/img-border2.webp";
import ImgBorder3 from "../../../assets/images/img-border3.webp";
import ImgBorder4 from "../../../assets/images/img-border4.webp";
import ImgBorder5 from "../../../assets/images/img-border5.webp";
import ReadySetGo from "../../../components/ReadySetGo/ReadySetGo";


const ServicesOffered = () => {
    return (
        <>
            <div className="d-flex justify-content-end mt-5 container-form-motion">
                <img src={FormMotionTribe} alt="" />
            </div>
            <section className="container-fluid d-flex flex-column align-items-center  mt-5 mb-5 container-parent-cards">
                <div className="container-fluid d-flex justify-content-start">
                    <article className="container-card-offered d-flex">
                        <div className="container-offered-img">
                            <img src={ImgBorder1} alt="img running" className="img-fluid" />
                        </div>
                        <div className="container-body-offered">
                            <p className="text-info ">Bodybuilding is the use of progressive resistance exercise to control and develop one's muscles by muscle hypertrophy for aesthetic purposes.</p>
                            <button className="btn btn-primary" >See More ...</button>
                        </div>
                    </article>
                </div>

                <div className="container-fluid d-flex justify-content-center">
                    <article className="container-card-offered d-flex">
                        <div className="container-offered-img-hidden-left">
                            <img src={ImgBorder2} alt="img running" className="img-fluid" />
                        </div>
                        <div className="container-body-offered">
                            <p className="text-info">Bodybuilding is the use of progressive resistance exercise to control and develop one's muscles by muscle hypertrophy for aesthetic purposes.</p>
                            <button className="btn btn-primary">See More ...</button>
                        </div>
                        <div className="container-offered-img-hidden-rigth">
                            <img src={ImgBorder2} alt="img running" className="img-fluid" />
                        </div>
                    </article>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="row-lg row-md col container-fluid  align-items-center justify-content-between ">
                        <div className="bg-form text-info d-flex justify-content-center align-items-center col-md-6">
                            <p className="p-5">
                                Bodybuilding is the use of progressive resistance exercise to control and develop one's muscles by muscle hypertrophy for aesthetic purposes.
                            </p>
                        </div>

                        <article className="container-card-offered d-flex col-md-6">
                            <div className="container-offered-img">
                                <img src={ImgBorder3} alt="img running" className="img-fluid" />
                            </div>
                            <div className="container-body-offered">
                                <p className="text-info">Bodybuilding is the use of progressive resistance exercise to control and develop one's muscles by muscle hypertrophy for aesthetic purposes.</p>
                                <button className="btn btn-primary">See More ...</button>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="container-fluid d-flex justify-content-center">

                    <article className="container-card-offered d-flex">

                        <div className="container-offered-img-hidden-left">
                            <img src={ImgBorder4} alt="img running" className="img-fluid" />
                        </div>

                        <div className="container-body-offered">
                            <p className="text-info">Bodybuilding hidden is the use of progressive resistance exercise to control and develop one's muscles by muscle hypertrophy for aesthetic purposes.</p>
                            <button className="btn btn-primary">See More ...</button>
                        </div>

                        <div className="container-offered-img-hidden-rigth">
                            <img src={ImgBorder4} alt="img running" className="img-fluid" />
                        </div>
                    </article>
                </div>

                <div className="container-fluid d-flex justify-content-start">
                    <article className="container-card-offered d-flex">
                        <div className="container-offered-img">
                            <img src={ImgBorder5} alt="img running" className="img-fluid" />
                        </div>
                        <div className="container-body-offered">
                            <p className="text-info">Bodybuilding is the use of progressive resistance exercise to control and develop one's muscles by muscle hypertrophy for aesthetic purposes.</p>
                            <button className="btn btn-primary">See More ...</button>
                        </div>
                    </article>
                </div>
                <div className="container-fluid d-flex justify-content-end pe-5"><ReadySetGo /></div>

            </section>
        </>
    );
};

export default ServicesOffered;