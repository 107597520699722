import React from "react";
//Components
import Loading from "../../../components/Loading/Loading";

const FormSigIn = () => {
  const visible = false;

  return (
    <>
      <Loading visible={visible} />
      <form action="" className="d-flex flex-column">
        <div className="mb-3">
          <label htmlFor="inputEmailLogin" className="form-label">EMAIL ADRESS</label>
          <input type="email" className="form-control text-light" id="inputEmailLogin" placeholder="name@example.com" />
        </div>
        <div className="mb-3 mt-3">
          <label htmlFor="inputPasswordLogin" className="form-label" >PASSWORD</label>
          <input type="password" className="form-control text-light" id="inputPasswordLogin" placeholder="Password" />
        </div>
        <div className="col-auto mt-4">
          <button type="submit" className="btn btn-primary ">CONFIRM</button>
        </div>
        <div className="mt-3">
          <a href='/'>Lost your password?</a>
        </div>
      </form>
    </>
  );
};
export default FormSigIn;