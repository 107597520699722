import "./styles/home-styles.css";
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
//Assets
import iconArrow from "../../assets/images/angle-right.png"
//Components
import Navbar from "../../components/navbar/navbar.jsx";
import AboutHome from "./AboutHome.jsx";
import WelcomeHome from "./WelcomeHome.jsx";
import Footer from "../../components/footer/Footer.jsx";
import Testiomonials from "./Testimonials.jsx";
import CarouselHome from "./CarouselHome.jsx";



const Home = () => {
  const params = useParams()
  console.log(params);
  //animation button
  const [animate, setAnimate] = useState(false);
  const handleClick = () => {
    setAnimate(true);
    setTimeout(() => {
      window.location.href = "/auth";
    }, 1500);
  }
  return (
    <>
      <div>
        <div className="container_home_bg" id="Home">
          <Navbar />
          <div className="container_content">
            <div className="d-flex justify-content-center" >
              <h1 className="title">The path to success</h1>
            </div>
            <div className="container_btn">
              <button className={`btn_start   ${animate ? 'animate' : ''}`} onClick={handleClick}>Start here </button>
              <img src={iconArrow} alt="" />
            </div>
          </div>
          <div className="d-flex container_usersSign ">
            <div className="d-flex flex-column align-items-end">
              <div className="number_users">+30K</div>
              <p className="d-flex">Users have already signed up.</p>
            </div>
          </div>
        </div>
        <div className="content">
          <WelcomeHome/>
          <hr />
          <AboutHome/>
          <hr />
          <CarouselHome/>
          <hr />
          <Testiomonials/>
        </div>
        <Footer/>
      </div>
    </>
  )
}

export default Home