// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container-form {
    width: 100%;
}

.form-radaria {
    width: 50%;
}

.cards-container-services {
    max-width: 80%;
}

.container-cards-little {
    width: 100%;
}

.container-cards-little>div {
    border-bottom: solid 1px var(--bs-primary);

}


/* Estilos para tabletas (ancho mínimo de 601px y máximo de 992px) */
@media (min-width: 601px) and (max-width: 992px) {
    .form-radaria {
        width: 25%;
    }



}

/* Estilos para computadoras de escritorio (ancho mínimo de 993px) */
@media (min-width: 993px) {
    .form-radaria {
        width: 15%;
    }

    .container-cards-little>div {
        border-bottom: none !important;
    }

    .borders-sides {
        border-left: solid 1px var(--bs-primary) !important;
        border-right: solid 1px var(--bs-primary);
    }

    .container-border-top {
        border-top: solid 1px var(--bs-primary);
    }

}`, "",{"version":3,"sources":["webpack://./src/Views/Services/SectionSummary/sectionSummary.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,0CAA0C;;AAE9C;;;AAGA,oEAAoE;AACpE;IACI;QACI,UAAU;IACd;;;;AAIJ;;AAEA,oEAAoE;AACpE;IACI;QACI,UAAU;IACd;;IAEA;QACI,8BAA8B;IAClC;;IAEA;QACI,mDAAmD;QACnD,yCAAyC;IAC7C;;IAEA;QACI,uCAAuC;IAC3C;;AAEJ","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n.container-form {\n    width: 100%;\n}\n\n.form-radaria {\n    width: 50%;\n}\n\n.cards-container-services {\n    max-width: 80%;\n}\n\n.container-cards-little {\n    width: 100%;\n}\n\n.container-cards-little>div {\n    border-bottom: solid 1px var(--bs-primary);\n\n}\n\n\n/* Estilos para tabletas (ancho mínimo de 601px y máximo de 992px) */\n@media (min-width: 601px) and (max-width: 992px) {\n    .form-radaria {\n        width: 25%;\n    }\n\n\n\n}\n\n/* Estilos para computadoras de escritorio (ancho mínimo de 993px) */\n@media (min-width: 993px) {\n    .form-radaria {\n        width: 15%;\n    }\n\n    .container-cards-little>div {\n        border-bottom: none !important;\n    }\n\n    .borders-sides {\n        border-left: solid 1px var(--bs-primary) !important;\n        border-right: solid 1px var(--bs-primary);\n    }\n\n    .container-border-top {\n        border-top: solid 1px var(--bs-primary);\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
