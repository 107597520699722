import React from 'react'
import Background_Image from '../../assets/images/Background_Memberships.webp'
import '../Memberships/memberships.css'
import Navbar from '../../components/navbar/navbar'
import Footer from '../../components/footer/Footer'


const Memberships = () => {
  return (
    <div className='memberships-container'>
      <Navbar />
      <div className='header-image'>
        <img src={Background_Image} alt='Membresias' className='img-fluid' />
        <div className='overlay-content'>
          <h1> Opciones de Membresias </h1>
          <p> Elige la membresia que mas te convenga </p>
        </div>
      </div>

      <div className='cards-container'>
        <div className='row justify-content-center'>
          <div className='col-md-4'>
            <div className='card platinum'>
              <div className='card-body'>
                <h5 className='card-title'> PLATINO </h5>
                <ul className='card-text'>
                  <li> Servicios </li>
                  <li> Servicios </li>
                  <li> Servicios </li>
                  <li> Servicios </li>
                  <li> Servicios </li>
                </ul>
                <h3> $199.00 / MES </h3>
                {/* <a href='#' className='btn btn-primary'>Suscribirse</a> */}
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card-black'>
              <div className='card-body'>
                <h5 className='card-title'> BLACK </h5>
                <ul className='card-text'>
                  <li> Servicios </li>
                  <li> Servicios </li>
                  <li> Servicios </li>
                  <li> Servicios </li>
                  <li> Servicios </li>
                </ul>
                <h3> $299.00 / MES </h3>
                {/* <a href='#' className='btn btn-primary'>Suscribirse</a> */}
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card-gold'>
              <div className='card-body'>
                <h5 className="card-title">GOLD</h5>
                <ul className="card-text">
                  <li>Servicios</li>
                  <li>Servicios</li>
                  <li>Servicios</li>
                  <li>Servicios</li>
                </ul>
                <h3>$399.00 / MES</h3>
                {/* <a href="#" className="btn btn-primary">Suscribirse</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='membership-info'>
        <p>Puedo probar Radaria Gratis? </p>
        <p>Puedo probar Radaria Gratis? </p>
        <p>Puedo probar Radaria Gratis? </p>
      </div>
      <Footer />
    </div>
  )
}

export default Memberships;