import React from 'react'
import "./services.css"
//Components
import Navbar from "../../components/navbar/navbar";
import SectionDescription from './SectionDescription/SectionDescription';
import Footer from "../../components/footer/Footer";

//Assets
import IconRunner from "../../assets/icons/Running.svg";
import IconWeights from "../../assets/icons/Weights.svg";
import SectionSummary from './SectionSummary/SectionSummary';
import ServicesOffered from './ServicesOffered/ServicesOffered';

const Services = () => {
  return (
    <>
      <div className="conatiner-fluid container-services ">
        <Navbar page={"services"} />
        <section className="container-info pe-4 ps-4">
          <h1 className="text-info text-center">
            Comprehensive Services for Runners in Radaria
          </h1>
          <p className="text-info fs-5 text-center mt-3">
            Encouraging an everioment that inspires and motivates runners to archive their goals and push their limits.
          </p>
          <div className="d-flex flex-column flex-md-row align-items-center">
            <span className="d-flex m-2 align-items-center container-icon text-info">
              <img src={IconRunner} width={50} alt="icon runner" className="m-2" />
              +1500 Trainer
            </span>
            <span className="d-flex m-2 align-items-center text-info">
              <img src={IconWeights} width={50} alt="icon weights" className="m-2" />
              80+ Exercise
            </span>
          </div>
        </section>
      </div>
      <section className="container-body-services">
        <SectionDescription />
        <SectionSummary />
        <ServicesOffered />
      </section>
      <Footer />
    </>
  )
}

export default Services