// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-validate-info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

.form-validate-info div {
    flex: auto 1 auto;
    /* 3 columnas por defecto */
    width: 200px;
    /* Tamaño mínimo para cada columna */
    padding: 5px;
}

/* Move */
form {
    font-family: CodecProRegular;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    max-width: 800px;
}

.input-error {
    border: none !important;
    border-bottom: 1px solid var(--bs-danger) !important;
}

input {
    height: 50px;
    width: 200px;
    background-color: transparent !important;
    border-bottom: 1px solid white !important;
}

.container-head-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
}

#nickName {
    text-align: center;
}

select {
    color: white !important;
    background-color: transparent !important;
}

/*Input File*/

.input-file {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 80%;

}`, "",{"version":3,"sources":["webpack://./src/Views/ValidateInfo/FormVlidateInfo/formvalidate.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,SAAS;IACT,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,YAAY;IACZ,oCAAoC;IACpC,YAAY;AAChB;;AAEA,SAAS;AACT;IACI,4BAA4B;IAC5B,eAAe;IACf,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,oDAAoD;AACxD;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,wCAAwC;IACxC,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,wCAAwC;AAC5C;;AAEA,aAAa;;AAEb;IACI,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,mBAAmB;;AAEvB","sourcesContent":[".form-validate-info {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    gap: 10px;\n    width: 100%;\n}\n\n.form-validate-info div {\n    flex: auto 1 auto;\n    /* 3 columnas por defecto */\n    width: 200px;\n    /* Tamaño mínimo para cada columna */\n    padding: 5px;\n}\n\n/* Move */\nform {\n    font-family: CodecProRegular;\n    font-size: 16px;\n    justify-content: center;\n    align-items: center;\n    max-width: 800px;\n}\n\n.input-error {\n    border: none !important;\n    border-bottom: 1px solid var(--bs-danger) !important;\n}\n\ninput {\n    height: 50px;\n    width: 200px;\n    background-color: transparent !important;\n    border-bottom: 1px solid white !important;\n}\n\n.container-head-form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 20%;\n}\n\n#nickName {\n    text-align: center;\n}\n\nselect {\n    color: white !important;\n    background-color: transparent !important;\n}\n\n/*Input File*/\n\n.input-file {\n    overflow: hidden;\n    position: relative;\n    width: 100%;\n    height: 0;\n    padding-bottom: 80%;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
