import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";
// import { MapboxMap } from "react-map-gl";
function App() {
  return (
      <Routes>
        {routes.map((page) => (
          <Route {...page} key={page}/>
        ))}
      </Routes>

  );
}
export default App;
